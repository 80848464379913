import { PURGE } from 'redux-persist';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'hooks/storeHooks';
import { BrandProvider } from 'components';
import TagManager from 'react-gtm-module';
import ErrorBoundary from 'containers/ErrorBoundary';
import { page } from 'utils/analytics';
import { useGetFeatureFlagsQuery } from 'store/services/features';
import { TBrand } from 'components/UI/Brand/brand';
import {
  initChat,
  hasActiveChat,
  indicateShowChat,
  indicateHideChat,
} from './utils/brightPattern';
import Layout from './hoc/Layout/Layout';
import HeaderMessage from './components/UI/HeaderMessage/HeaderMessage';
import { setPersistentUTMQuery } from './utils/analytics';
import './App.css';
import { RenderRoutes } from './routes/RenderRoutes';

declare global {
  interface Window {
    brand?: TBrand;
    dataLayer: Record<string, unknown>[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SERVICE_PATTERN_CHAT_CONFIG: any;
  }

  interface Document {
    documentMode?: number;
  }
}
const {
  REACT_APP_COMPANY,
  REACT_APP_GOOGLE_TAG_MANAGER_ID_HX,
  REACT_APP_GOOGLE_TAG_MANAGER_ID_HURTIGRUTEN,
} = process.env;

const tagManagerArgs = {
  gtmId:
    REACT_APP_COMPANY === 'HX'
      ? REACT_APP_GOOGLE_TAG_MANAGER_ID_HX
      : REACT_APP_GOOGLE_TAG_MANAGER_ID_HURTIGRUTEN,
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  const location = useLocation();
  const [isAppError, setIsAppError] = useState(false);
  const dispatch = useAppDispatch();

  useGetFeatureFlagsQuery();
  useEffect(() => {
    initChat();
  }, []);

  useEffect(() => {
    if (!hasActiveChat()) {
      indicateShowChat();
    } else {
      indicateHideChat();
    }
  }, []);

  useEffect(() => {
    setPersistentUTMQuery(location.search);
  }, []);

  useEffect(() => {
    page(location.pathname);
  }, [location]);

  useEffect(() => {
    if (isAppError) {
      setIsAppError(false);
      // handle the action in the store.ts
      dispatch({
        type: PURGE,
        key: 'root',
        result: () => null,
      });
    }
  }, [isAppError]);

  return (
    <BrandProvider>
      <ErrorBoundary setIsAppError={setIsAppError}>
        <Layout>
          <div className="headerRoot">
            <HeaderMessage />
          </div>
          <>
            <RenderRoutes />
          </>
        </Layout>
      </ErrorBoundary>
    </BrandProvider>
  );
};

export default App;
