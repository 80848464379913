import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ButtonText } from 'components/UI/Brand/text';
import clsx from 'clsx';
import translate from 'translate';
import { media } from 'style/sizes';
import { executeScroll } from 'utils/executeScroll';
import Icon from 'components/UI/Icons/Icon';
import { colors } from 'style/colors';
import { useMediaQuery } from 'hooks/useMediaQuery';
import styles from './styles.module.scss';

interface INavItem {
  label: string;
  onClickHandler: () => void;
}

interface IAnchorNavigationHeader {
  items: INavItem[];
  activeItem: string;
  hasBackground?: boolean;
}

const AnchorNavigationHeader = ({
  items,
  activeItem,
  hasBackground = false,
}: IAnchorNavigationHeader) => {
  const modifiedActiveItem = activeItem?.replaceAll(' ', '').toLowerCase();
  const isDesktopScreen = useMediaQuery(media.minLaptop);
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [, setActiveItem] = useState(modifiedActiveItem);
  const [isScroll, setScroll] = useState(false);
  const headerRef = useRef(null);
  const location = useLocation();
  const { pathname, search } = useLocation();

  const observer = useRef(
    new window.IntersectionObserver(
      ([event]) => {
        if (event.boundingClientRect.top < 0) {
          setScroll(true);
        } else {
          setScroll(false);
        }
      },
      { threshold: [0.99] }
    )
  );

  useEffect(() => {
    const { current: currentObserver } = observer;
    currentObserver.disconnect();
    const stickyElm = headerRef.current;
    if (stickyElm) {
      currentObserver.observe(stickyElm);
    }
    return () => {
      currentObserver.disconnect();
    };
  }, [headerRef]);

  useEffect(() => {
    if (location?.hash) {
      const { hash } = location;
      let locationOnSite = hash.split('#')[1];
      locationOnSite = decodeURI(locationOnSite);
      executeScroll(locationOnSite, 100);
      setActiveItem(locationOnSite);
    }
  }, []);

  useMemo(() => {
    const scrollArea = document.getElementById('anchorNavigation');
    const scrollToItem = document.getElementById(`id-${modifiedActiveItem}`);
    if (scrollArea && scrollToItem) {
      const distanceMinusPadding = scrollToItem.offsetLeft - 24;
      scrollArea.scrollTo({
        left: distanceMinusPadding,
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [activeItem]);

  return (
    <div className={styles.navigation}>
      <nav
        id="anchorNavigation"
        className={clsx(styles.navigation, {
          [styles.hasScroll]: isScroll,
          [styles.hasBackground]: hasBackground,
        })}
        ref={headerRef}
      >
        {!isDesktopScreen && (
          <button
            aria-label={translate('General_Navigation_OpenMenu')}
            className={styles.menuButton}
            onClick={() => setMenuVisible(!isMenuVisible)}
          >
            <ButtonText>
              {!activeItem || isMenuVisible
                ? `${translate('General_JumpTo')}...`
                : activeItem}
            </ButtonText>
            <Icon
              color={colors.shared.darkRed}
              iconType={isMenuVisible ? 'arrowUp' : 'arrowDown'}
              height={24}
              width={24}
            />
          </button>
        )}
        {(isDesktopScreen || isMenuVisible) && (
          <ul
            className={clsx(styles.list, {
              [styles.hasNoScroll]: !isScroll,
            })}
            onClick={() =>
              !isDesktopScreen && isMenuVisible && setMenuVisible(false)
            }
          >
            {items.map(({ label, onClickHandler }) => {
              const squashedLowerCaseLabel = label
                ?.trim()
                .replaceAll(' ', '')
                .toLowerCase();

              return (
                <li
                  key={`id-${squashedLowerCaseLabel}`}
                  onClick={onClickHandler}
                >
                  <Link
                    to={`${pathname}${search}#${squashedLowerCaseLabel}`}
                    id={`id-${squashedLowerCaseLabel}`}
                    className={clsx(styles.navItem, {
                      [styles.navItemActive]:
                        modifiedActiveItem === squashedLowerCaseLabel,
                    })}
                  >
                    {label}
                  </Link>
                </li>
              );
            })}
            {isDesktopScreen && isScroll && (
              <button
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                className={styles.navItem}
                aria-label={translate('General_BackToTop')}
              >
                <Icon
                  color={colors.shared.darkRed}
                  iconType="arrowUpLong"
                  height={24}
                  width={24}
                />
              </button>
            )}
          </ul>
        )}
      </nav>
    </div>
  );
};

export default AnchorNavigationHeader;
